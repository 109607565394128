import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import Component from 'react-component-component';
import { Alert, Button } from '../../src';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "alert"
    }}>{`Alert`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import Alert from 'mfcl/Alert'
`}</code></pre>
    <p>{`Alerts are to assist communication between the user and the UI`}</p>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <Props of={Alert} mdxType="Props" />
    <h2 {...{
      "id": "colors"
    }}>{`Colors`}</h2>
    <ul>
      <li parentName="ul">{`You can pass in anything with the children prop`}</li>
    </ul>
    <Playground __position={1} __code={'<Alert color=\"error\" style={{ width: \'300px\' }}>\n  Error: you are receiving a crazy error\n</Alert>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      Alert,
      Button,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Alert color="error" style={{
        width: '300px'
      }} mdxType="Alert">
    Error: you are receiving a crazy error
  </Alert>
    </Playground>
    <h2 {...{
      "id": "controlled-alert"
    }}>{`Controlled Alert`}</h2>
    <ul>
      <li parentName="ul">{`Use the collapse or fade prop (or both!) for a smoother transition`}</li>
      <li parentName="ul">{`Or set them to false if you don't want an animated transition (for some reason..)`}</li>
    </ul>
    <Playground __position={2} __code={'<Component\n  initialState={{\n    isOpen1: true,\n    isOpen2: true,\n    isOpen3: true,\n  }}\n>\n  {({ setState, state }) => (\n    <>\n      <Alert\n        color=\"error\"\n        isOpen={state.isOpen1}\n        onClose={() => setState({ isOpen1: false })}\n      >\n        This alert can be closed/controlled\n      </Alert>\n      <Alert\n        color=\"error\"\n        isOpen={state.isOpen3}\n        onClose={() => setState({ isOpen3: false })}\n      >\n        This alert has a fade and collapse animation\n      </Alert>\n      <Alert\n        color=\"error\"\n        isOpen={state.isOpen2}\n        onClose={() => setState({ isOpen2: false })}\n        collapse={false}\n      >\n        This alert has a fade animation\n      </Alert>\n      <Button\n        onClick={() =>\n          setState({\n            isOpen1: true,\n            isOpen2: true,\n            isOpen3: true,\n          })\n        }\n      >\n        Refresh\n      </Button>\n    </>\n  )}\n</Component>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      Alert,
      Button,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Component initialState={{
        isOpen1: true,
        isOpen2: true,
        isOpen3: true
      }} mdxType="Component">
    {({
          setState,
          state
        }) => <>
        <Alert color="error" isOpen={state.isOpen1} onClose={() => setState({
            isOpen1: false
          })} mdxType="Alert">
          This alert can be closed/controlled
        </Alert>
        <Alert color="error" isOpen={state.isOpen3} onClose={() => setState({
            isOpen3: false
          })} mdxType="Alert">
          This alert has a fade and collapse animation
        </Alert>
        <Alert color="error" isOpen={state.isOpen2} onClose={() => setState({
            isOpen2: false
          })} collapse={false} mdxType="Alert">
          This alert has a fade animation
        </Alert>
        <Button onClick={() => setState({
            isOpen1: true,
            isOpen2: true,
            isOpen3: true
          })} mdxType="Button">
          Refresh
        </Button>
      </>}
  </Component>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      